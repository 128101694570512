<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Owner'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [categoryList]="['RESPONSIBILITIES', 'GROUPS', 'USERS']"
        [principleModeSelection]="'SELECTION'"
        [label]="'Responders'"
        [placeholder]="'Search...'"
        [control]="formGroup?.controls?.responders"
        [multi]="true"
        [viewMode]="fieldViewMode"
        [selectedEntities]="data?.responders"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
    </div>
    <!-- <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.scoringType.label' | translate: 'Scoring Type'"
        [placeholder]="moduleFieldString + '.scoringType.placeholder' | translate: 'Enter Scoring Type'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="listOfScoringType"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.scoringType"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div> -->
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.questionnaire.label' | translate: 'Questionnaire'"
        [placeholder]="moduleFieldString + '.questionnaire.placeholder' | translate: 'Enter Questionnaire'"
        [control]="formGroup?.controls?.questionnaire"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.closeDate.label' | translate: 'Close Date'"
        [placeholder]="moduleFieldString + '.closeDate.placeholder' | translate: '11/22/2024'"
        [name]="'closeDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.closeDate"
        [viewMode]="fieldViewMode"
        [minDate]="minDate"
      ></app-date-input>
    </div>
    <!-- <div class="col-12 md:col-6">
      <app-number-input
        [label]="moduleFieldString + '.score.label' | translate: 'Score'"
        [placeholder]="moduleFieldString + '.score.placeholder' | translate: 'Enter Score'"
        [name]="'Score'"
        [showButtons]="true"
        [control]="formGroup?.controls?.score"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
        [min]="0"
      ></app-number-input>
    </div> -->
    <!-- @if(data && data?.progress){
      <div class="col-12">
        
      </div>
    } -->
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
