import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, CampaignDto, ModuleKeywords, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-campaign-item-form',
  templateUrl: './campaign-item-form.component.html',
  styleUrl: './campaign-item-form.component.scss',
})
export class CampaignItemFormComponent extends BaseForm<CampaignDto> implements OnInit {
  minDate = new Date();

  listOfScoringType = getEnumOptions(CampaignDto.ScoringTypeEnum);
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Campaign);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      scoringType: new FormControl(null),
      responders: new FormControl(null),
      owner: new FormControl(null),
      closeDate: new FormControl(null),
      questionnaire: new FormControl(null),
      // score: new FormControl(null),
    });
  }
}
